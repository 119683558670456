<template>
  <div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
    <CRow>
			<CCol sm="6">
				<CCard>
					<CCardHeader>
						<strong>JADWAL KUNJUNGAN</strong> DI DATA CENTER
					</CCardHeader>
					<br>
					<CCardBody v-for="row in visits" :key="row.id">

							<CRow>
								<CCol sm="6">
									<CSelect 
										:value.sync="row.company_id"
										name="company_id"
										label="Nama Perusahaan"
										:options="companies"
										disabled
									/>
								</CCol>
								<CCol sm="6">
									<CSelect 
										:value.sync="row.user_id"
										name="user_id"
										label="Nama Pemohon"
										:options="users"
										disabled
									/>
								</CCol>
							</CRow>
							<CRow>
								<CCol sm="4">
									<CSelect 
										:value.sync="row.site_id"
										name="site_id"
										label="Data Center"
										:options="sites"
										disabled
									/>
								</CCol>
								<CCol sm="4">
									<!-- <CSelect
										:value.sync="row.room_id"
										name="room_id"
										label="Ruangan"
										:options="rooms"
										disabled
									/> -->
									<CTextarea
										type="text"
										label="Ruangan"
										placeholder=""
										v-model="row.room_id"
										disabled
									/>
								</CCol>
								<CCol sm="4">
									<CInput
										type="text"
										label="Rak/Cage"
										placeholder=""
										v-model="row.rack"
										disabled
									/>
								</CCol>
							</CRow>
              <CRow>
								<CCol sm="8">
									<CRow>
										<CCol sm="6">
											<CInput
												type="text"
												label="Tanggal Mulai"
												placeholder=""
												v-model="row.start_date.split(' ')[0]"
												disabled
											/>
										</CCol>
										<CCol sm="6">
											<CInput
												type="text"
												label="Jam Mulai"
												v-model="row.start_date.split(' ')[1]"
												disabled
											/>
										</CCol>
										<CCol sm="6">
											<CInput
												type="text"
												label="Tanggal Selesai"
												v-model="row.end_date.split(' ')[0]"
												disabled
											/>
										</CCol>
										<CCol sm="6">
											<CInput
												type="text"
												label="Jam Selesai"
												:value="row.end_date.split(' ')[1]"
												@change="getEnd($event)"
												:disabled="visitStatus === 'Closed' || role > 5"
											>
												<template #append>
                					<CButton :disabled="visitStatus === 'Closed' || role > 5" size="sm" color="danger" @click="visitExtend(row.end_date.split(' ')[0])"><CIcon name="cil-pencil"/></CButton>
              					</template>
											</CInput>
										</CCol>
									</CRow>
								</CCol>
								
								<CCol sm="4" class="text-center align-middle">
									<CCard style="margin-top: 28px;">
										<CCardHeader :color="getStatus(row.status)">
											<strong>STATUS </strong>
										</CCardHeader>
										<CCardBody style="max-height: 72px;">
											<h4>{{row.status}}</h4>
											<!-- <h1 v-if="row.status !== 'On-Going'">{{row.end_date | formatTime}}:00</h1>
											<h1 v-if="row.status === 'On-Going'">{{hours}}:{{minutes}}:{{seconds}}</h1> -->
										</CCardBody>
									</CCard>
								</CCol>
							</CRow>
					</CCardBody>
				</CCard>
			</CCol>
      <CCol sm="6">
				<CCard>
					<CCardHeader>
						<strong>DETAIL PENGAJUAN</strong> IJIN MASUK DAN BEKERJA DI DATA CENTER
					</CCardHeader>
					<CCardBody v-for="row in visits" :key="row.id">
						<div style="min-height: 332px;">
              <CRow>
								<CCol sm="6">
									<CSelect 
										:value.sync="row.activity_id"
										name="activity_id"
										label="Kegiatan"
										placeholder="Pilih Keperluan Kunjungan"
										:options="activities"
										disabled
									/>
								</CCol>
								<CCol sm="6">
									<CSelect 
										:value.sync="row.priority"
										name="priority"
										label="Prioritas"
										placeholder="Pilih Prioritas Kunjungan"
										:options="priorities"
										disabled
									/>
								</CCol>
							</CRow>
							<CTextarea
								label="Detail Kegiatan"
								rows="4"
								v-model="row.detail_activity"
								readonly
							/>
							<CRow>
								<CCol sm="6">
									<CSelect 
										:value.sync="row.unplugCable"
										name="unplugCable"
										label="Pasang / Cabut Perangkat"
										placeholder="Pilih.."
										:options="hardwares"
										disabled
									/>
								</CCol>
								<CCol sm="6">
									<CSelect 
										:value.sync="row.unplugDevice"
										name="unplugDevice"
										label="Pasang / Cabut Kabel"
										placeholder="Pilih.."
										:options="cables"
										disabled
									/>
								</CCol>
							</CRow>
						</div>
					</CCardBody>
				</CCard>
			</CCol>
      <CCol sm="12">
				<CCard>
					<CCardHeader>
						<strong>DAFTAR NAMA</strong> PENGUNJUNG DI DATA CENTER
					</CCardHeader>
					<CCardBody>
						<CDataTable
							hover
							striped
							border
							small
							sorter
							:items="workers"
							:fields="fields"
							:items-per-page="10"
							:loading="isLoading"
						>
							<template #id="{item}">
								<td align="center">{{workers.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
							</template>
							<template #expired_at="{item}">
								<td align="center">
									<p v-if="item.expired_at">VALID</p>
									<a v-if="!item.expired_at" style="cursor: pointer; color: red;" @click="signNda(item.user_id)">EXPIRED</a>
								</td>
          		</template>
							<template #status="{item}">
            		<td align="center">
									<CBadge :color="getBadge(item.status)">{{item.status === null ? 'BELUM' : 'SUDAH'}}</CBadge>
            		</td>
          		</template>
							<template #role="{item}">
								<td>
									{{item.role}}
									<br>
									<small>Access card: <strong>{{item.access_card_number}}</strong></small>
								</td>
							</template>
							<template #email="{item}">
								<td>
									{{item.email}}
									<br>
									<small>Phone: <strong>{{item.mobile_phone}}</strong></small>
								</td>
							</template>
							<template #company_name="{item}">
								<td>{{item.role === 'Guest' ? item.note : item.company_name}}</td>
							</template>
							<template #action="{item}">
            		<td align="center">
              		<CButton color="info" variant="outline" square size="sm" @click="detailVisitor(item.user_id)">Detail Pengunjung</CButton> &nbsp; 
									<CButton 
										color="danger" 
										variant="outline" 
										square 
										size="sm" 
										v-if="visitStatus === 'On-Going' && role <= 5 && role !== 4 && item.status === null" 
										:disabled="!item.expired_at"
										@click="checkIn(item.user_id)"
									>Check In</CButton>
									<CButton 
										color="warning" 
										variant="outline" 
										square 
										size="sm" 
										v-if="visitStatus === 'On-Going' && role <= 5 && role !== 4 && item.status === 'on_going'" 
										@click="checkIn(item.user_id)"
									>Re-Check In</CButton>
									<CButton 
										color="success" 
										variant="outline" 
										square 
										size="sm" 
										v-if="visitStatus === 'On-Going' && role <= 5 && role !== 4 && item.status === 'check_in'" 
										:disabled="item.status === 'check_out'"
										@click="checkOut(item.user_id)"
									>Check Out</CButton>
									<CButton 
										color="dark" 
										variant="outline" 
										square 
										size="sm" 
										v-if="visitStatus === 'On-Going' && role <= 5 && role !== 4 && item.status === 'check_out'" 
										:disabled="item.status === 'check_out'"
									>Checked Out</CButton>
									<!-- <CButton color="danger" v-if="!isCheckIn" disabled @click="toast('Pengunjung sudah check-in.')">Checked-In</CButton> -->
            		</td>
          		</template>
						</CDataTable>
					</CCardBody>
				</CCard>
			</CCol>
			<CCol sm="12">

				<CCard v-for="row in bast" :key="row.id">
					<CCardHeader>
						<CRow>
							<CCol sm="9">
								<strong>DAFTAR BARANG</strong> {{row.status === 0 ? 'KELUAR DARI' : 'MASUK KE'}} DATA CENTER
							</CCol>
							<CCol sm="3" class="text-right" v-if="visitStatus !== 'Closed'">
								<CButton color="success" variant="outline" square size="sm" :disabled="row.first_party_sign !== null" @click="openModal(row.id, 0, row.status, [])">ADD ITEM</CButton> &nbsp;
								<CButton color="warning" variant="outline" square size="sm" :disabled="role > 5 || role === 4" @click="approvalBast(row.id)">APPROVAL BAST</CButton>
							</CCol>
						</CRow>
					</CCardHeader>
					<CCardBody>
						<CDataTable
							hover
							striped
							border
							small
							sorter
							pagination
							:items="row.detail"
							:fields="fields2"
							:items-per-page="10"
							:loading="isLoading"
						>
							<template #isDualFeed="{item}">
            		<td align="center">
              		{{item.isDualFeed === 0 ? 'Tidak' : 'Ya'}}
            		</td>
          		</template>
							<template #rack="{item}">
            		<td align="left">
              		{{item.rack === null ? '' : item.rack}}
            		</td>
          		</template>
							<template #total="{item}">
            		<td align="right">
              		{{item.total}}
            		</td>
          		</template>
							<template #note="{item}">
            		<td align="left">
              		{{item.note === null ? '' : item.note}}
            		</td>
          		</template>
							<template #action="{item}">
								<td align="center">
									<CButton
										:disabled="visitStatus === 'Closed' || row.first_party_sign !== null"
            				color="primary"
            				variant="outline"
            				square
            				size="sm"
            				@click="openModal(item.handover_id, item.id, row.status, row.detail)"
          				>
            				edit
          				</CButton>&nbsp;&nbsp;
									<CButton
										:disabled="visitStatus === 'Closed' || row.first_party_sign !== null"
            				color="danger"
            				variant="outline"
            				square
            				size="sm"
            				@click="delItem(item.handover_id, item.id)"
          				>
            				delete
          				</CButton>
								</td>
							</template>
						</CDataTable>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CModal
      color="primary"
      :show.sync="editModal"
			:no-close-on-backdrop="true"
    	:centered="true"
    >
      <CRow>
				<CCol sm="6">
					<CInput
						type="text"
						label="Nama Barang"
						v-model="selectedItem.item_name"
						placeholder=""
						required
					/>
				</CCol>
				<CCol sm="6">
					<CInput
						type="text"
						label="Serial Number / No. Reg LA"
						v-model="selectedItem.serial"
						placeholder=""
						required
					/>
				</CCol>
				<CCol sm="4">
					<CInput
						type="number"
						label="Jumlah"
						v-model="selectedItem.total"
						placeholder=""
						required
					/>
				</CCol>
				<CCol sm="4">
					<CInput
						type="text"
						label="Rack"
						v-model="selectedItem.rack"
						placeholder=""
						required
					/>
				</CCol>
				<CCol sm="4">
					<CSelect 
						:value.sync="selectedItem.isDualFeed"
						name="items.isDualFeed"
						label="Dual Feed"
						placeholder="Pilih.."
						:options="dualFeed"
						required
						/>
				</CCol>
				<CCol sm="12">
						<CTextarea
							rows="3"
							label="Keterangan / Fungsi"
							v-model="selectedItem.note"
							required
						/>
				</CCol>
			</CRow>
			<template #header>
      	<h6 class="modal-title">Ubah Data Barang {{bastStatus === 1 ? 'Masuk' : 'Keluar'}}</h6>
      	<CButtonClose @click="editModal = false" class="text-white"/>
    	</template>
    	<template #footer>
      	<CButton @click="cancelBast(selectedItem.handover_id || 0)" color="">Batal</CButton>
      	<CButton v-if="selectedItem.id > 0" @click="updateBast(selectedItem.handover_id, selectedItem.id, selectedItem)" color="">Ubah</CButton>
				<CButton v-if="!selectedItem.id" @click="updateBast(selectedItem.handover_id, 0, selectedItem)" color="">Tambah</CButton>
    	</template>
    </CModal>

  </div>
</template>

<script>
import http from '@/utils/http-common';

export default {
  name: 'PageVisitRequest',
  data () {
		return {
      id: this.$route.params.id,
			role: +localStorage.getItem('role'),
			editModal: false,
			details: [],
			collapseDuration: 0,
			fields: [
				'id',
				'name',
				{ key: 'company_name', label: 'Company' },
				'role',
				{ key: 'email', label: 'Contact' },
				{ key: 'expired_at', label: 'NDA' },
				'status',
				{ key: 'action', label: ' ' }
			],
			fields2: [
				{ key: 'item_name', label: 'Nama' }, 
				'serial', 
				'total', 
				'rack', 
				{ key: 'isDualFeed', label: 'Dual Feed' }, 
				'note', 
				// { key: 'show_details', label: '', _style: 'width:1%', sorter: false, filter: false }
				{ key: 'action', label: ' ' }, 
			],
      companies: [],
			company_id: 0,
			site_id: 0,
			activities: [],
      sites: [],
			users: [],
			rooms: [],
      visits: [],
			workers: [],
			bastStatus: 0,
			visitStatus: '',
			bast: null,
			selectedItem: [],
			items: [],
			isLoading: true,
			isCheckIn: false,
			dualFeed: [
				{value: 1, label: 'Ya'}, 
				{value: 0, label: 'Tidak'}
			],
			links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Pengajuan Kunjungan',
          href: '#/approval/visit-request'
        }, 
        {
          text: 'Detail Pengajuan Kunjungan'
        }
      ],
			priorities: [
				{
					label: 'Normal',
					value: 0
				},
				{
					label: 'Tinggi',
					value: 1
				},
			],
			hardwares: [
				{
					label: 'Ya',
					value: 1
				},
				{
					label: 'Tidak',
					value: 0
				},
			],
			cables: [
				{
					label: 'Ya',
					value: 1
				},
				{
					label: 'Tidak',
					value: 0
				},
			],
			endTime: '',

      date: 0,
      interval: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      intervals: {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24
      }
		}
	},
	computed: {
		/* time: function(){
			return this.date_now.format('DD MMMM YYYY HH:mm:ss')
		} */
	},
	methods: {
		toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
		toast(message) {
      this.$toast.open({
        message: message,
        type: "info", // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
		getStatus(status) {
			return status === 'Pending' ? 'danger'
				: status === 'On-Going' ? 'warning'
					: status === 'Canceled' ? 'dark'
						: status === 'Closed' ? 'success' : 'primary'
		},
		getBadge (status) {
			return status !== null ? 'success' : 'dark'
		},
		getOut(status) {
			/* for (var i = 0; i < items.length; i++) {
    		if (items[i].check_in === 1) {
					return true;
				} else {
					return false;
				}
  		} */
			if (status === null) {
				return false
			} else if (status === 'check_in') {
				return true
			} else if (status === 'on_going') {
				return true
			} else if (status === 'check_out') {
				return true
			}
		},
		detailVisitor(id) {
      this.$router.push({ path: this.id + '/' + id });
    },
		signNda(uid) {
			this.$router.push({ path: '/settings/users/form/' + uid });
		},
		approvalBast(id) {
      this.$router.push({ path: '/approval/bast/' + this.id + '/' + id });
    },
		checkIn(uid) {
			let self = this;
			return http.put('/visits/checkIn/' + self.id + '/' + uid)
			.then(function (response) {
				// console.log(response)
				// self.isCheckIn = true;
				self.getData(self.id).then(()=> {
					self.toast('Check-in berhasil.');
				});
			}).catch(function (error) {
				console.log(error);
			});
		},
		checkOut(uid) {
			let self = this;
			return http.put('/visits/checkOut/' + self.id + '/' + uid)
			.then(function (response) {
				// console.log(response)
				// self.isCheckIn = true;
				self.getData(self.id).then(()=> {
					self.toast('Check-out berhasil.');
				});
			}).catch(function (error) {
				console.log(error);
			});
		},
		delItem(bast_id, id) {
			if (confirm("Barang Akan Dihapus. Lanjut?")) {
    		let self = this;
				self.items = self.items.filter(item => item.id !== id);
				self.removeBast(bast_id, id);
  		}
		},
		openModal(bast_id, id, status, items) {
			let self = this;
			if (id > 0) {
				self.bastStatus = status;
				self.items = items;
				self.selectedItem = items.filter(item => item.id === id)[0];
			} else {
				self.bastStatus = status;
				self.items = [];
				self.selectedItem = {
					handover_id: bast_id,
					item_name: '',
					serial: '',
					total: 0,
					rack: '',
					isDualFeed: 0,
					note: ''
				}
			}
			self.editModal = true;
		},
		updateBast(bast_id, id, items) {
			let self = this;
			if (id > 0) {
				return http.put('/handover/updateItem/' + bast_id + '/' + id, items)
					.then(function () {
						self.getData(self.id).then(()=> {
							self.editModal = false;
							self.toast('Data Barang Berhasil Diubah.');
						});
					}).catch(function (error) {
						console.log(error);
					});
			} else {
				return http.post('/handover/addItem/' + bast_id, items)
					.then(function () {
						self.getData(self.id).then(()=> {
							self.editModal = false;
							self.toast('Data Barang Berhasil Ditambahkan.');
						});
					}).catch(function (error) {
						console.log(error);
					});
			}
		},
		removeBast(bast_id, id) {
			let self = this;
			return http.delete('/handover/remItem/' + bast_id + '/' + id)
					.then(function () {
						self.getData(self.id).then(()=> {
							self.toast('Data Barang Berhasil Dihapus.');
						});
					}).catch(function (error) {
						console.log(error);
					});
		},
		cancelBast(id) {
			let self = this;
			if (id > 0) {
				self.getData(self.id).then(()=> {
					self.editModal = false;
					self.toast('Memuat Data Awal.');
				});
			} else {
				self.editModal = false;
			}
		},
    getCompanies (){
			const self = this;
			return http.get('/companies')
			.then(function (response) {
				const data = response.data.data;
        
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['company_name'];
					items['value'] = data[i]['id'];
					self.companies.push(items)
				}

			}).catch(function (error) {
				console.log(error);
			});
		},
    getSites (){
			let self = this;
			return http.get('/sites')
			.then(function (response) {
				const data = response.data.data;
        
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['site_name'];
					items['value'] = data[i]['id'];
					self.sites.push(items)
				}
				
			}).catch(function (error) {
				console.log(error);
			});
		},
		getRooms (){
			let self = this;
			return http.get('/rooms')
			.then(function (response) {
				const data = response.data.data;
        
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['room_name'];
					items['value'] = data[i]['id'];
					self.rooms.push(items);
				}
				
			}).catch(function (error) {
				console.log(error);
			});
		},
		getUsers() {
			let self = this;
			return http.get('/users')
			.then(function (response) {
				const data = response.data.users;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['name'];
					items['value'] = data[i]['id'];

					// put only activated users into the list
					if (data[i]['status'] === 'Active') {
						self.users.push(items);
					}
				}
				
			}).catch(function (error) {
				console.log(error);
			});
		},
		getActivities() {
			let self = this;
			return http.get('/activities')
			.then(function (response) {
				const data = response.data.data;
        
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['activity_name'];
					items['value'] = data[i]['id'];
					self.activities.push(items)
				}
				
			}).catch(function (error) {
				console.log(error);
			});
		},
    getData(id) {
			let self = this;
			return http.get('/visits/' + id)
			.then(function (response) {

				// console.log(response.data.data)

				self.visits = response.data.data;
				self.visitStatus = response.data.data[0].status;
				self.date = response.data.data[0].end_date;

				self.workers = response.data.workers;

				// use if not using standalone bast request
				// self.bastStatus = response.data.handover.status;
				self.bast = response.data.handover;
				// console.log(response.data.handover)
				// self.items = response.data.handover.detail;

				/* response.data.handover.forEach((val, index) => {
					self.bastStatus = val.status;
					self.bast = val;
					self.items = val.detail;
				}) */

				response.data.data.forEach((value, index) => {
    			self.site_id = value.site_id;
					self.company_id = value.company_id;
					self.getRooms(value.site_id)
				});
				
				self.isLoading= false;
			}).catch(function (error) {
				console.log(error);
				self.isLoading= false;
			});
		},
		getBast(id) {
			let self = this;
			return http.get('/handover/visit/' + id)
			.then(function (response) {
				self.items = response.data.data.detail;
				self.bastStatus = response.data.data.status;
				self.bast = response.data.data;
			}).catch(function (error) {
				console.log(error);
			});
		},
		getEnd(event) {
			this.endTime = event;
		},
		visitExtend(date) {
			let self = this;
			let endAt = date + ' ' + self.endTime;
			return http.put('/visits/' + self.id, { end_date: endAt })
				.then(function (response) {
					self.toast('Berhasil Menambahkan Waktu Jadwal Kunjungan.', 'info');
				}).catch(function (error) {
					console.log(error);
					self.toast(error.message, 'error');
				});
		},
		updateDiffs(date) {
      //lets figure out our diffs
      let diff = Math.abs(Date.now() - new Date(date).getTime());
      this.days = String(Math.floor(diff / this.intervals.day)).padStart(2, '0');
      diff -= this.days * this.intervals.day;
      this.hours = String(Math.floor(diff / this.intervals.hour)).padStart(2, '0');
      diff -= this.hours * this.intervals.hour;
      this.minutes = String(Math.floor(diff / this.intervals.minute)).padStart(2, '0');
      diff -= this.minutes * this.intervals.minute;
      this.seconds = String(Math.floor(diff / this.intervals.second)).padStart(2, '0');
    },
	},
	mounted() {
		this.getData(this.id).then(() => {
			return this.getUsers();
		}).then(() => {
			return this.getCompanies();
		}).then(() => {
			return this.getSites();
		}).then(() => {
			return this.getRooms();
		}).then(() => {
			this.getActivities();
		})
		// this.getBast(this.id)
		
		/* this.interval = setInterval(() => {
      this.updateDiffs(this.date);
    },1000);
    
    this.updateDiffs(this.date); */
	}
}
</script>
<style scoped>
CSelect:disabled, CSelect[readonly] {
  background-color: #fff!important;
  opacity: 1;
}
</style>
